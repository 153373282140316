import { getFirestore, collection , doc, setDoc, updateDoc, getDoc, getDocs, Timestamp, deleteDoc, deleteField, orderBy, query, where, limit, startAt, startAfter, onSnapshot } from "firebase/firestore";
import { getFirebaseBackend  as getFirbase  }  from './authUtils';

class FirebaseModelBackend {
    
    constructor() {}

    db(){
        return getFirestore(getFirbase().firebaseApp);
    }

    createPreproccess(collectionPath:string,docData:any,id:string=null){
        let docRef = doc(collection(this.db(), collectionPath));
        if(id){
            docRef = doc(this.db(),`${collectionPath}/${id}`)
        }
        let created_at = Timestamp.fromDate(new Date());
        docData['id'] = docRef.id;
        docData['created_at'] = created_at;
        docData['updated_at'] = created_at;
        return {docRef, docData};
    }
    updatePreproccess(docData:any={}){
        docData['updated_at'] = Timestamp.fromDate(new Date());
        return docData;
    }
    createNewDocumentByCollectionPath = (collectionPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const { docRef, docData } = this.createPreproccess(collectionPath, data);
            setDoc(docRef, docData).then(() => {
                resolve(getDoc(docRef));
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    createDocumentWithSetIDAndcollectionPath = (id: string, collectionPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const { docRef, docData } = this.createPreproccess(collectionPath, data, id);
            setDoc(docRef, docData).then(() => {
                resolve(getDoc(docRef));
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    editDocumentByDocPath = (docPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const docRef = doc(this.db(),docPath);
            const docData = this.updatePreproccess(data);
            updateDoc(docRef, docData).then(() => {
                resolve(getDoc(docRef));
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    editDocumentByDeletingMap = (docPath: string, map: string) => {
        return new Promise((resolve, reject) => {
            const docRef = doc(this.db(),docPath);
            const docData = this.updatePreproccess();
            updateDoc(docRef,{ ...docData, [map]: deleteField() }).then(() =>
                resolve(getDoc(docRef))
            ), (error) => {
                reject(this._handleError(error));
            }
        })
    }
    deleteDocumentByDocPath = (docPath: string) => {
        return new Promise((resolve, reject) => {
            const docRef = doc(this.db(),docPath);
            deleteDoc(docRef).then(
                function (doc) {
                    resolve(getDoc(docRef))
                }
            ), (error) => {
                reject(this._handleError(error));
            }
        })
    }
    getDocumentByDocPath = (docPath: string) => {
        return new Promise((resolve, reject) => {
            const docRef = doc(this.db(),docPath);
            getDoc(docRef).then((doc: any) => {
                const docData = { id: doc.id, ...doc.data() };
                resolve(docData);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getCollictionDocsWithLimitFirst = (collectionPath: string, orderByField: string, orderType:string, limitNum: number) => {
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const first = query(collectionRef, orderBy(orderByField ,orderType==='desc' ? "desc" : "asc"), limit(limitNum))
            const documents = [];
            getDocs(first).then((documentSnapshots: any) => {
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,"path":collectionPath,lastVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getCollictionDocsWithLimitNext = (collectionPath:any, orderByField: string, orderType:string, limitNum: number, lastVisible:any) => {
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const next = query(collectionRef, orderBy(orderByField ,orderType==='desc' ? "desc" : "asc"), startAt(lastVisible) , limit(limitNum))
            const documents = [];
            getDocs(next).then((documentSnapshots: any) => {
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,lastVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getCollictionDocsWithLimitPrev = (collectionPath:any, orderByField: string, orderType:string, limitNum: number, lastVisible:any) => {
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const perv = query(collectionRef, orderBy(orderByField ,orderType==='desc' ? "desc" : "asc"), startAt(lastVisible) , limit(limitNum))
            const documents = [];
            getDocs(perv).then((documentSnapshots: any) => {
                const FirstVisible = documentSnapshots.docs[0];
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,FirstVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getCollectionDoucmentsByCollectionPath = (collectionPath: string) => {
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const documents = [];
            getDocs(collectionRef).then((documentSnapshots: any) => {
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve(documents);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getDocByUniqueProperty(collectionPath: string, prop: string, value: string) {
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const q = query(collectionRef, where(prop, "==", value), limit(1));
            getDocs(q).then((querySnapshot) => {
                if(!querySnapshot.empty){
                    querySnapshot.forEach((doc) => {
                        resolve({ id: doc.id, ...doc.data() });
                    });
                } 
                else{
                    resolve(null)
                }    
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }

    async checkDocsExistanceInCollectionWithUniqueProperty(collectionPath: string, property: string, value: string) {
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, where(property, "==", value), limit(1));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty == false){
            return true;
        } 
        else{
            return false
        }
    }

    async checkDocsExistanceInCollectionWithCoupleProperties(collectionPath,properties:any){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, 
            where(properties[0].name, "==", properties[0].value), 
            where(properties[1].name, "==", properties[1].value), 
            limit(1)
        );
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty == false){
            return true;
        } 
        else{
            return false
        }
    }

    async getDocumentsWithSpecificProperty(collectionPath:string, property:string, value:string){
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const q = query(collectionRef, where(property, "==", value));
            getDocs(q).then((querySnapshot) => {
                if(!querySnapshot.empty){
                    const docs = [];
                    querySnapshot.forEach((doc) => {
                        docs.push({ id: doc.id, ...doc.data() });
                    });
                    resolve(docs);
                } 
                else{
                    resolve(null);
                }    
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }

    async getNumOcceranceDocsInCollectionWithCoupleProperties(collectionPath,properties:any){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, 
            where(properties[0].name, "==", properties[0].value), 
            where(properties[1].name, "==", properties[1].value), 
            limit(1)
        );
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty == false){
            return querySnapshot.docs.length;
        } 
        else{
            return 0;
        }
    }

    makeCollectionGroupQuery(collectionPath:string,searchKey:string,value:string){
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const q = query(collectionRef, where(searchKey, "==", value), limit(1));
            getDocs(q).then((querySnapshot) => {
                querySnapshot.forEach((doc)=>{
                    resolve({id:doc.id, data:doc.data()});
                })
                resolve(null);
            }, (error) => {
                reject(this._handleError(error));
            });
        })
    }

    makeCollectionGroupArrayContainQuery(collectionPath:string,searchKey:string,value:string){
        return new Promise((resolve, reject) => {
            const collectionRef = collection(this.db(), collectionPath);
            const q = query(collectionRef, where(searchKey, 'array-contains', value), limit(1));
            getDocs(q).then((querySnapshot) => {
                querySnapshot.forEach((doc)=>{
                    resolve({id:doc.id, data:doc.data()});
                })
                resolve(null);
            }, (error) => {
                reject(this._handleError(error));
            });
        })
    }

    getDocumentWithListener(docPath:string,cb){
        const docRef = doc(this.db(),docPath);
        onSnapshot(docRef,(doc:any)=>{
            if(doc){
                const docData = { id: doc.id, ...doc.data() };
                cb(docData,false);
            }else{
                cb(null,true);
            }
        })
    }
    getCollicationUpdatesWithListener(collectionPath:string,orderByField:string,orderType:string,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, orderBy(orderByField ,orderType==='desc' ? "desc" : "asc"));
        onSnapshot(q,(querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb(docs,false);
            }    
        })
    }
    getCollicationUpdatesWithListenerWithCondition(collectionPath:string,searchKey:string,value:string,orderByField:string,orderType:string,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, where(searchKey, '==', value), orderBy(orderByField, orderType==='desc' ? "desc" : "asc"));
        onSnapshot(q,(querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb(docs,false);
            }
        })
    }

    getCollicationWithListenerWithConditionFirst(collectionPath:string,searchKey:string,value:string,orderByField: string,orderType:string, limitNum: number,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, where(searchKey, '==', value), orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), limit(limitNum));
        onSnapshot(q,(querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,"path":collectionPath,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerFirst(collectionPath:string,orderByField: string,orderType:string, limitNum: number,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), limit(limitNum));
        onSnapshot(q, (querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,"path":collectionPath,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerNext(collectionPath:string,orderByField: string,orderType:string, limitNum: number,LastVisible:any,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), startAfter(LastVisible), limit(limitNum));
        onSnapshot(q,(querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerWithConditionNextStartAt(collectionPath:string,searchKey:string,value:string,orderByField: string,orderType:string, limitNum: number,LastVisible:any,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, where(searchKey, '==', value), orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), startAt(LastVisible), limit(limitNum));
        onSnapshot(q,(querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerNextStartAt(collectionPath:string,orderByField: string,orderType:string, limitNum: number,LastVisible:any,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), startAt(LastVisible), limit(limitNum));
        onSnapshot(q, (querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerPrev(collectionPath:string,orderByField: string,orderType:string, limitNum: number,LastVisible:any,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const q = query(collectionRef, orderBy(orderByField, orderType==='desc' ? "desc" : "asc"), startAfter(LastVisible), limit(limitNum));
        onSnapshot(q, (querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }


    getCollicationWithOrder(collectionPath:string,orderByField: string,orderType:string,cb){
        const collectionRef = collection(this.db(), collectionPath);
        const ordered = query(collectionRef, orderBy(orderByField ,orderType==='desc' ? "desc" : "asc"));
        onSnapshot(ordered,(documentSnapshots: any) => {
            if(documentSnapshots){
                let documents = [];
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                cb(documents,false);
            } 
        });
    }
    

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(localStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = new FirebaseModelBackend();

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { getFirebaseBackend };
