import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Timestamp, deleteField, increment } from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class SharedOperationsService {


  private companySource = new BehaviorSubject(new String);
  companyData = this.companySource.asObservable();

  constructor(private cookieService: CookieService) { }
  
  getFirestoreTimeStamp(){
    return Timestamp.fromDate(new Date());
  }

  deleteFirestoreDocumentField(){
    return deleteField()
  }

  returnIncrement(){
    return increment(1);
  }
  
  returnDecrement(){
    return increment(-1);
  }

  getTimeStamp() {
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var today: any = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');
    today = months[parseInt(today.getMonth())] + " " + dd + ", " + yyyy + " at " + time;
    return today;
  }
  /////////////////
  setKainicPidInLocalStorage=async(Pid: string)=> {
    localStorage.setItem('kainic_pid',Pid);
  }
  getKainicPidFromLocalStorage() {
    return localStorage.getItem('kainic_pid');
  }
  checkKainicPid=async()=>{
    // await this.data.subscribe(pid=>{
    //   if(pid!==null){
    //     console.log("there is pid in local storage =",pid);
    //     return true
    //   }
    //   else{
    //     return false
    //   }
    // })
    await localStorage.getItem('kainic_pid') != null;
  }
  ///////////////////
  setCompanyIDinStore(cid:string){
    //  this.companySource.next(cid);
    sessionStorage.setItem('cid', JSON.stringify(cid))
    // console.log("updated company from store =",cid);
  }
  getCompanyIDfromStore(){
    // this.companyData.subscribe(cid=>{
    //   if(cid!==null){
    //     console.log("cid from store =",cid);
    //     return cid
    //   }
    //   else{
    //     return null
    //   }
    // })
    if (!sessionStorage.getItem('cid')) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('cid'));
  }
  //////////////////
  setTrackerinStorage(Tracker: any) {
    sessionStorage.setItem('tracker', JSON.stringify(Tracker))
  }
  getTrackerFromStorage() {
    if (!sessionStorage.getItem('tracker')) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('tracker'));
  }
  /////////////////////////////
  setKainicTidInStorage(Tid: string) {
    sessionStorage.setItem('kainic_tid', Tid)
  }

  getKainicTidFromStorage() {
    if (!sessionStorage.getItem('kainic_tid')) {
      return null;
    }
    return sessionStorage.getItem('kainic_tid');
  }
  /////////////////////////////////
  setModelInStorage(model: any) {
    sessionStorage.setItem('model',JSON.stringify(model))
  }

  getModelFromStorage() {
    if (!sessionStorage.getItem('model')) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('model'));
  }
  ////////////////////////////////////
  setModelIdInStroge(mid:string){
    {
      sessionStorage.setItem('modelID', mid)
    }
  }
  getModelIdInStroge(){
    if (!sessionStorage.getItem('modelID')) {
      return null;
    }
    return sessionStorage.getItem('modelID');
  }
  ////////////////////////////////////
  setTrainingJobInStorage(trainingJob:any){
    // console.log("from storeage")
    sessionStorage.setItem('trainingJob',JSON.stringify(trainingJob));
  }
  getTrainingJobFromStorage(){
    if (!sessionStorage.getItem('trainingJob')) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('trainingJob'));
  }
  //////////////////////////////////
  setExperimentIDinStorage=async(EID:string)=>{
    sessionStorage.setItem('experiment_id', EID);
  }
  getExperimentIdInStroge(){
    if (!sessionStorage.getItem('experiment_id')) {
      return null;
    }
    return sessionStorage.getItem('experiment_id');
  }
  ////////////////////////////////////
  clearStorage(){
    sessionStorage.clear();
  }
  ///////////////////////////////////
  clearCookieCash(){
    this.cookieService.delete('kainic_pid');
    this.cookieService.deleteAll();
  }
  ////////////////////////////////////
  generateApiKey(){
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  //////////////////////////////
  copyTextToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  ////////////////////////
  setAuthenticatedUser(user:any){
    localStorage.setItem('authUser',JSON.stringify(user));
  }
  ///////////////////////
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) {
        return null;
    }
    return JSON.parse(localStorage.getItem('authUser'));
}


  
}
