import { initializeApp } from "firebase/app";
import { gcp_project_id } from 'src/environments/environment';
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, EmailAuthProvider, updatePassword, reauthenticateWithCredential } from "firebase/auth";

export interface User {
    firstName: string,
    lastName: string
}

class FirebaseAuthBackend {

    firebaseApp = null;
    auth = null;

    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            this.firebaseApp = initializeApp(firebaseConfig);
            this.auth = getAuth(this.firebaseApp);
            this.auth.onAuthStateChanged((user) => {
                if (user) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                } else {
                    localStorage.removeItem('authUser');
                }
            });
            this.auth.onIdTokenChanged(function(user) {
                if (user) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                }
            });
        }
    }
    /**
     * Send verification email
     */
    SendVerificationMail(){
        return new Promise((resolve, reject)=>{
            const type =gcp_project_id.split('-')[0];
            let URL= 'console.kainic.ai';
            if(type==='development'){
                URL="dev-console.kainic.net";
            }
            const actionCodeSettings = {
                url: `https://${URL}/account/login`,
                handleCodeInApp: true,
            };
            sendEmailVerification(this.auth.currentUser, actionCodeSettings).then(()=>{
                resolve(true);
            }).catch((err)=>{
                reject(err);
            })
        })
    }
    /**
     * Registers the user with given details
     */
    registerUser = (userData: any) => {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(this.auth, userData.email, userData.password).then((res: any) => {
                const user: any = this.auth.currentUser;
                //Send Verification email
                this.SendVerificationMail();
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }
    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(this.auth, email, password).then((res: any) => {
                // eslint-disable-next-line no-redeclare
                this.setLoggeedInUser(res.user)
                const user: any = this.auth.currentUser;    
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }
    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            sendPasswordResetEmail(this.auth, email, { url: window.location.protocol + '//' + window.location.host + '/login' }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }
    /**
     * check current password
     */
    checkCurrentPassword(providedPassword: string) {
        return new Promise((resolve, reject) => {
            const user = this.auth.currentUser;
            const credential = EmailAuthProvider.credential(
                this.auth.currentUser.email,
                providedPassword
            );
            reauthenticateWithCredential(user, credential).then(function () {
                resolve(true)
            }).catch(function (e) {
                reject(false)
            });
        })
    }
    /**
     * Update user Password
     */
    updatePassword (password: string) {
        return new Promise((resolve, reject) => {
            const user = this.auth.currentUser;
            updatePassword(user, password).then(() => {
                resolve(true)
            }).catch((error) => {
                reject(false)
            });
        })
    }
    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            this.auth.signOut().then(() => {
                localStorage.clear();
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    setLoggeedInUser = (user) => {
        localStorage.setItem('authUser', JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(localStorage.getItem('authUser'));
    }

    /**
     * return user emailVerification status
     */
    isUserEmailVerified = () =>{
        const user=this.getAuthenticatedUser();
        if(user){
            return user.emailVerified ;
        }
        else{
            return null;
        }

    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        const errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend  };
