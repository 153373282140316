import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SharedOperationsService } from './shared-operations.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private SharedOperationsService: SharedOperationsService) { }
  // create a method named: resolveItems()
  // this method returns list-of-items in form of Observable
  // every HTTTP call returns Observable object
  postRequest(url, body,params?:any): Observable<any> {
    // console.log('Request is sent!');
    // console.log('URL Api:', url);
    // console.log('Body Api:', body);
    var auth_user = this.SharedOperationsService.getAuthenticatedUser();
    var accessToken = auth_user.stsTokenManager.accessToken;
    // Using the POST method
    let headers:any = {
      headers: new HttpHeaders(
        {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }),
    };
    //headers.observe='body';

    if(params){
      headers.params=new HttpParams().set(params.name,params.value);
    }
    return this.http.post(url, body, headers).pipe(
      catchError(this.handleError)
    )
  }

  patchRequest(url,body,params?:any): Observable<any> {
    const auth_user = this.SharedOperationsService.getAuthenticatedUser();
    const accessToken = auth_user.stsTokenManager.accessToken;
    // Using the POST method
    const headers:any = {
      headers: new HttpHeaders(
        {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }),
    };

    if(params){
      headers.params=new HttpParams().set(params.name,params.value);
    }
    return this.http.patch(url, body, headers).pipe(
      catchError(this.handleError)
    )
  }

  deleteRequest(url, body, params?:any): Observable<any> {
    const auth_user = this.SharedOperationsService.getAuthenticatedUser();
    const accessToken = auth_user.stsTokenManager.accessToken;
    // Using the POST method
    const options:any = {
      headers: new HttpHeaders(
        {
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }),
      body: body
    };

    if(params){
      options.params=new HttpParams().set(params.name,params.value);
    }
    return this.http.delete(url, options).pipe(
      catchError(this.handleError)
    )
  }

  postRequestWithFormData(url, formData:FormData){
    return this.http.post(url, formData).pipe(
      catchError(this.handleError)
    )
  }

  postRequestWithout(url, body,params?:any): Observable<any> {
    let headers:any = {
      headers: new HttpHeaders(
        {
          'Content-Type':  'application/json'
        }),
    };

    if(params){
      headers.params=new HttpParams().set(params.name,params.value);
    }
    return this.http.post(url, body, headers).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // console.log(error);
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(new String(error));
  }
}

