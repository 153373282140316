import { Component, OnInit,ViewChild,Output,EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedOperationsService } from '../../../core/services/shared-operations.service';

import { GeneralOperationsService } from '../../../core/services/general-operations.service';

import { CredentialsService} from '../../../core/services/credentials.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss'],
    standalone: false
})
export class CredentialsComponent implements OnInit {
  @ViewChild('credentialsModal') credentialsModal: any;

  visibility: boolean = false;
  credentials: string;

  @Output() modalClosed = new EventEmitter<boolean>();

  docData:any;

  credentialModalOpened:any
  subscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private generalService:GeneralOperationsService,
    private SharedOperationsService:SharedOperationsService,
    private CredentialsService:CredentialsService
    ) {
  }


  centerModal(centerDataModal: any) {
    // const opendModal=this.modalService.open(centerDataModal, { centered: true, windowClass: 'modal-holder' })
    // opendModal.result.finally(()=>{
    //   if(centerDataModal===this.credentialsModal){
    //     console.log("main model closed");
    //     this.onModalClose();
    //   }
    // });
    this.modalService.open(centerDataModal, { centered: true, windowClass: 'modal-holder' })
  }

  changeVisiablity() {
    this.visibility = !this.visibility;
  }
  closeVisiabilty() {
    this.visibility = false;
  }

  onChangeAPIkeyClick(confirmationModal) {
    if (this.visibility) {
      this.centerModal(confirmationModal);
    }
  }

  copyAPIkeyToClipboard() {
    this.SharedOperationsService.copyTextToClipboard(this.credentials);
  }

  onChangeAPIKeyConfirmed() {
    this.docData.body.api_key=this.SharedOperationsService.generateApiKey();
    this.generalService.updateDocByID(this.docData.type,this.docData.body.id,this.docData.body,this.docData.pathPre).then((res:any)=>{
      this.credentials=this.docData.body.api_key;
    })
  }

  showCredentials(content: any, doc: any) {
    this.closeVisiabilty();
    this.credentials = doc.api_key;
    this.centerModal(content);
  }

  onModalClose(){
    this.modalClosed.emit(true);
  }

  ngOnInit(): void {
    this.subscription=this.CredentialsService.currentDocData.subscribe((docData:any)=>{
      if(docData){
        // console.log("from credentional component=",docData)
        this.docData=docData;
        this.modalService.dismissAll();
        this.showCredentials(this.credentialsModal,docData.body)
      }
    })
  }


}
