import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../firebaseBackend/firestoreUtils';
import { get_Firestore_path } from '../../firebaseBackend/fireStoreCollectionsPath';
@Injectable({
  providedIn: 'root'
})
export class GeneralOperationsService {

  constructor() { }
  createDocByDefault(dataType: string, docData: any, pathPre?: any) {
    const collectionPath = get_Firestore_path(dataType, pathPre);
    return getFirebaseBackend().createNewDocumentByCollectionPath(collectionPath, docData).then((doc: any) => {
      return doc;
    })
  }

  createDocWithDocID(dataType: string, docData: any, DocID: string, pathPre?: any) {
    const collectionPath = get_Firestore_path(dataType, pathPre, DocID);
    return getFirebaseBackend().createDocumentWithSetIDAndcollectionPath(DocID, collectionPath, docData).then((doc: any) => {
      return doc;
    })
  }

  getDocDataByDocID(dataType: string, DocID: string, pathPre?: any) {
    const docPath = get_Firestore_path(dataType, pathPre, DocID);
    return getFirebaseBackend().getDocumentByDocPath(docPath).then((doc: any) => {
      return doc;
    })
  }

  getAllCollectionDocs(dataType: string, pathPre?: any) {
    const collectionPath = get_Firestore_path(dataType, pathPre);
    return getFirebaseBackend().getCollectionDoucmentsByCollectionPath(collectionPath).then((docs: any) => {
      return docs
    })
  }

  getCollectionDocsFirstWithOrderAndDocsNumberFirstTime(dataType: string, orderField: string, orderType: string, docsNumber: number, pathPre?: any) {
    const collectionPath = get_Firestore_path(dataType, pathPre);
    return getFirebaseBackend().getCollictionDocsWithLimitFirst(collectionPath, orderField, orderType, docsNumber).then((docs: any) => {
      return docs;
    })
  }

  getCollectionDocsFirstWithOrderAndDocsNumberNextTime(dataType: string, orderField: string, orderType: string, docsNumber: number, lastVisiable: any, pathPre?: any) {
    const collectionPath = get_Firestore_path(dataType, pathPre);
    return getFirebaseBackend().getCollictionDocsWithLimitNext(collectionPath, orderField, orderType, docsNumber, lastVisiable).then((docs: any) => {
      return docs;
    })
  }

  updateDocByID(dataType: string, DocID: string, DocData: any, pathPre?: any) {
    let docPath = get_Firestore_path(dataType, pathPre, DocID);
    return getFirebaseBackend().editDocumentByDocPath(docPath, DocData).then((doc: any) => {
      return doc
    })
  }

  deleteDocByID(dataType: string, DocID: string, pathPre?: any) {
    const docPath = get_Firestore_path(dataType, pathPre, DocID);
    return getFirebaseBackend().deleteDocumentByDocPath(docPath).then((doc) => {
      return doc;
    })
  }


}
